import Component from "../lib/Component";
import Slider from "../lib/Slider";
import gsap, { Power1 } from "gsap";

export default class TestimonialsFloor extends Component {
  mount({ root, slider, nav }) {
    const navs = [...nav.children];
    root.classList.remove("loading");

    const slider = new Slider({
      source: slider,
      autoplay: true,
      interval: 3000,
      update(currentItem) {
        navs.forEach((nav, i) => {
          nav.classList.toggle("active", currentItem === i);
        });
      },
    });

    navs.forEach((nav, i) => {
      nav.addEventListener("click", () => {
        slider.go(i);
      });
    });
  }

  unmount() {}
}
