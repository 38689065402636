import Component from "../lib/Component";
import gsap, {Power1} from "gsap";

export default class MetodologiaFloor extends Component {
  mount({ root, slider, scroller, slides, prev, next, badges }) {
    let current = 1;

    const go = (slide) => {
      current = Math.min(Math.max(1, slide), 3);
      slider.dataset.slide = current;

      const item = slides[current - 1];
      const x = item.offsetLeft + item.clientWidth / 2 - window.innerWidth / 2;
      gsap.to(scroller, { duration: 0.3, scrollTo: { x, autoKill: true }, ease: Power1.easeInOut });
    };

    prev.addEventListener("click", (e) => {
      e.preventDefault();
      go(current - 1);
      console.log("click");
    });

    next.addEventListener("click", (e) => {
      e.preventDefault();
      go(current + 1);
    });


    badges.forEach(badge => {
      badge.addEventListener("click", (e) => {
        go(badge.dataset.slide);
      });
    })

  }

  unmount() {}
}
