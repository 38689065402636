import Component from "../lib/Component";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class ParallaxElement extends Component {
  mount({ root, element }) {
    const { offset = 10 } = root.dataset;

    this.tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: root,
          // markers: true,
          scrub: .3,
        },
      })
      .add(gsap.timeline().fromTo(element, { yPercent: -offset }, { yPercent: offset }));
  }

  unmount({ root }) {
    this.tl.kill();
  }
}
