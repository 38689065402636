import Component from "../lib/Component";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Video extends Component {
  mount({ root, video }) {
    const enable = () => {
      root.classList.add("active");
      video.src = video.dataset.src;
    };
    const disable = () => {
      root.classList.remove("active");
      video.src = "";
    };

    this.tl = gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: root,
        onEnter: enable,
        onEnterBack: enable,
        onLeave: disable,
        onLeaveBack: disable,
      },
    });
  }

  unmount({ root }) {
    this.tl.kill();
  }
}
