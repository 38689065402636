import Component from "../lib/Component";
import gsap from "gsap";

export default class AboutFloor extends Component {
  mount({ root, pin, space }) {
    // console.log(root, pin, space);
    gsap.timeline({
      ease: "none",
      scrollTrigger: {
        trigger: space,
        start: "top top",
        end: "bottom bottom",
        scrub: 0.3,
        invalidateOnRefresh: true,
        // markers: true,

        onEnter() {
          space.dataset.pinned = "center";
        },
        onLeaveBack() {
          space.dataset.pinned = "top";
        },
        onEnterBack() {
          space.dataset.pinned = "center";
        },
        onLeave() {
          space.dataset.pinned = "bottom";
        },
        // onUpdate: self => console.log("progress:", self.progress)
        onUpdate(self) {
          root.dataset.slide = Math.max(1, Math.ceil(self.progress * 4));
          // console.log(Math.min(1, Math.ceil(self.progress * 3)));
        },
      },
    });
    //   .add([
    //     // gsap.fromTo(media1, { yPercent: 10 }, { yPercent: -10 }),
    //     // gsap.fromTo(media2, { yPercent: -40 }, { yPercent: -20 }),
    //     gsap.fromTo(media3, { yPercent: -10 }, { yPercent: 0 }),
    //   ]);
  }

  unmount() {}
}
