// import gsap from "gsap";

const stopThresholdDefault = 0.5;
const bounceDeceleration = 0.04;
const bounceAcceleration = 0.11;

// fixes weird safari 10 bug where preventDefault is prevented
// @see https://github.com/metafizzy/flickity/issues/457#issuecomment-254501356
window.addEventListener("touchmove", function () {});

export default class Slider {
  constructor({
    source: sourceEl = document,
    update: updateCallback,
    end: endCallback,
    multiplier = 1,
    friction = 0.92,
    initialSlide = 0,
    interval = 1000,
    autoplay = false,
  }) {
    let slides = [];
    let slideWidth;
    let wrapWidth;
    let sliderWidth;

    let pointerLastPos;
    let pointerCurrentPos;
    let pointerId;

    let decVel = 0;
    let currentPos = 0;
    let targetPos = 0;

    let stopThreshold = stopThresholdDefault * multiplier;
    let ticking = false;
    let pointerActive = false;
    let paused = false;
    let decelerating = false;
    let trackingPoints = [];

    let currentItem = 0;

    let playing = false;
    let timeoutId = null;
    this.interval = interval;

    function resize() {
      slideWidth = slides[0].clientWidth;
      wrapWidth = slideWidth * slides.length;
      sliderWidth = sourceEl.clientWidth;

      currentPos = initialSlide * -slideWidth;
      slides.forEach((slide, i) => {
        const offset = slideWidth * (i + 1);
        const val = wrap(-offset, wrapWidth - offset, currentPos) + slideWidth * i;
        slide.style.transform = `translateX(${val}px)`;
      });
    }

    /**
     * Initialize instance
     */
    (function init() {
      sourceEl = typeof sourceEl === "string" ? document.querySelector(sourceEl) : sourceEl;
      if (!sourceEl) {
        throw new Error("SLIDER: source not found.");
      }

      slides = [...sourceEl.children];
      // this.slides = slides;
      resize();

      sourceEl.addEventListener("touchstart", onDown);
      sourceEl.addEventListener("mousedown", onDown);
    })();

    window.addEventListener("resize", resize);

    /**
     * Removes all events set by this instance during runtime
     */
    function cleanUpRuntimeEvents() {
      // Remove all touch events added during 'onDown' as well.
      document.removeEventListener("touchmove", onMove, getPassiveSupported() ? { passive: false } : false);
      document.removeEventListener("touchend", onUp);
      document.removeEventListener("touchcancel", stopTracking);
      document.removeEventListener("mousemove", onMove, getPassiveSupported() ? { passive: false } : false);
      document.removeEventListener("mouseup", onUp);
    }

    /**
     * Add all required runtime events
     */
    function addRuntimeEvents() {
      cleanUpRuntimeEvents();

      // @see https://developers.google.com/web/updates/2017/01/scrolling-intervention
      document.addEventListener("touchmove", onMove, getPassiveSupported() ? { passive: false } : false);
      document.addEventListener("touchend", onUp);
      document.addEventListener("touchcancel", stopTracking);
      document.addEventListener("mousemove", onMove, getPassiveSupported() ? { passive: false } : false);
      document.addEventListener("mouseup", onUp);
    }

    function wrap(min, max, value) {
      const range = max - min;
      return ((range + ((value - min) % range)) % range) + min;
    }

    /**
     * Executes the update function
     */
    function callUpdateCallback() {
      slides.forEach((slide, i) => {
        const offset = slideWidth * (i + 1);
        const val = wrap(-offset, wrapWidth - offset, currentPos) + slideWidth * i;

        slide.style.transform = `translateX(${val}px)`;
      });

      const offsetItem = Math.floor((currentPos * -1 + slideWidth * 0.5) / slideWidth) % slides.length;
      currentItem = offsetItem < 0 ? slides.length + offsetItem : offsetItem;
      updateCallback && updateCallback.call(sourceEl, currentItem, currentPos, decVel);
    }
    /**
     * Executes the update function
     */
    function callEndCallback() {
      currentPos = targetPos;
      callUpdateCallback();
      endCallback && endCallback.call(sourceEl, currentPos);

      playing && play();
    }

    function easeInOutQuart(x) {
      return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
    }

    this.goCallback = function go(index) {
      timeoutId && clearTimeout(timeoutId);

      const startPos = currentPos;
      let offset = index - currentItem;

      console.log(Math.abs(slides.length + index - currentItem), Math.abs(offset));
      if (Math.abs(slides.length + index - currentItem) < Math.abs(offset)) {
        offset = slides.length + index - currentItem;
      } else if (Math.abs(slides.length - index + currentItem) < Math.abs(offset)) {
        offset = (slides.length - index + currentItem) * -1;
      }

      const finalLength = -offset * slideWidth;

      const duration = 1000;
      let startTime;
      trackingPoints = [];

      const goAnim = (delta) => {
        if (!startTime) startTime = delta;
        const currentTime = delta - startTime;
        const progress = Math.max(0, Math.min(1, currentTime / duration));
        currentPos = startPos + finalLength * easeInOutQuart(progress);
        callUpdateCallback();

        if (delta - startTime < duration) {
          requestAnimationFrame(goAnim);
        } else {
          stopTracking();
          callEndCallback();
        }
      };

      requestAnimationFrame(goAnim);
    };

    this.prevCallback = () => {
      const index = currentItem === 0 ? slides.length - 1 : currentItem - 1;
      this.goCallback(index);
    };

    this.nextCallback = () => {
      const index = currentItem === slides.length - 1 ? 0 : currentItem + 1;
      this.goCallback(index);
    };

    const play = () => {
      playing = true;
      timeoutId && clearTimeout(timeoutId);
      timeoutId = setTimeout(() => this.nextCallback(), interval);
    };

    /**
     * Initializes movement tracking
     * @param  {Object} ev Normalized event
     */
    function onDown(ev) {
      var event = normalizeEvent(ev);
      if (!pointerActive && !paused) {
        pointerActive = true;
        decelerating = false;
        pointerId = event.id;

        pointerLastPos = pointerCurrentPos = event.x;
        trackingPoints = [];
        addTrackingPoint(pointerLastPos);

        addRuntimeEvents();
        timeoutId && clearTimeout(timeoutId);
      }
    }

    /**
     * Handles move events
     * @param  {Object} ev Normalized event
     */
    function onMove(ev) {
      ev.preventDefault();
      var event = normalizeEvent(ev);

      if (pointerActive && event.id === pointerId) {
        pointerCurrentPos = event.x;
        addTrackingPoint(pointerLastPos);
        requestTick();
      }
    }

    /**
     * Handles up/end events
     * @param {Object} ev Normalized event
     */
    function onUp(ev) {
      var event = normalizeEvent(ev);

      if (pointerActive && event.id === pointerId) {
        stopTracking();
      }
    }

    /**
     * Stops movement tracking, starts animation
     */
    function stopTracking() {
      pointerActive = false;
      addTrackingPoint(pointerLastPos);
      startDecelAnim();

      cleanUpRuntimeEvents();
    }

    /**
     * Records movement for the last 100ms
     * @param {number} x
     * @param {number} y [description]
     */
    function addTrackingPoint(pos) {
      var time = Date.now();
      while (trackingPoints.length > 0) {
        if (time - trackingPoints[0].time <= 100) {
          break;
        }
        trackingPoints.shift();
      }

      trackingPoints.push({ pos, time });
    }

    /**
     * Calculate new values, call update function
     */
    function updateAndRender() {
      var pointerChangeX = pointerCurrentPos - pointerLastPos;

      currentPos += pointerChangeX * multiplier;

      let diff = getDiff();
      if (diff !== 0) {
        currentPos -= pointerChangeX * dragOutOfBoundsMultiplier(diff) * multiplier;
      }

      callUpdateCallback();

      pointerLastPos = pointerCurrentPos;
      ticking = false;

      // update();
    }

    /**
     * Returns a value from around 0.5 to 1, based on distance
     * @param {Number} val
     */
    function dragOutOfBoundsMultiplier(val) {
      return 0.000005 * Math.pow(val, 2) + 0.0001 * val + 0.55;
    }

    /**
     * prevents animating faster than current framerate
     */
    function requestTick() {
      if (!ticking) {
        requestAnimFrame(updateAndRender);
      }
      ticking = true;
    }

    /**
     * Determine position relative to bounds
     * @param {Boolean} restrict Whether to restrict target to bounds
     */
    function getDiff() {
      if (currentPos < targetPos || currentPos > targetPos) {
        return targetPos - currentPos;
      }

      return 0;
    }

    function snap(x) {
      return Math.round(x / slideWidth) * slideWidth;
    }

    /**
     * Initialize animation of values coming to a stop
     */
    function startDecelAnim() {
      var firstPoint = trackingPoints[0];
      var lastPoint = trackingPoints[trackingPoints.length - 1];

      var xOffset = lastPoint.pos - firstPoint.pos;
      var timeOffset = lastPoint.time - firstPoint.time;
      //   var D = timeOffset / 15 / multiplier;
      var D = timeOffset / 30 / multiplier;

      decVel = xOffset / D || 0; // prevent NaN

      const totalDistance = getDistance(decVel, currentPos);
      const numItem = snap(totalDistance);
      targetPos = numItem;

      var diff = getDiff();

      if (Math.abs(decVel) > 1 || diff !== 0) {
        decelerating = true;
        requestAnimFrame(stepDecelAnim);
      } else {
        callEndCallback();
      }
    }

    function getDistance(decVelX, targetX) {
      let flag = 100;
      while (flag-- > 0 && Math.abs(decVelX) > stopThreshold) {
        decVelX *= friction;
        targetX += decVelX;
      }

      return targetX;
    }

    /**
     * Animates values slowing down
     */
    function stepDecelAnim() {
      if (!decelerating) {
        return;
      }

      decVel *= friction;
      currentPos += decVel;

      var diff = getDiff();

      if (Math.abs(targetPos - currentPos) > 1 && (Math.abs(decVel) > stopThreshold || diff !== 0)) {
        let reboundAdjust = 2.5;

        if (diff !== 0) {
          if (diff * decVel <= 0) {
            decVel += diff * bounceDeceleration;
          } else {
            let adjust = diff > 0 ? reboundAdjust : -reboundAdjust;
            decVel = (diff + adjust) * bounceAcceleration;
          }
        }

        callUpdateCallback();

        requestAnimFrame(stepDecelAnim);
      } else {
        decelerating = false;
        callEndCallback();
      }
    }

    callUpdateCallback();
    if (autoplay) play();
  }

  go(index) {
    return this.goCallback(index);
  }

  prev() {
    return this.prevCallback();
  }

  next() {
    return this.nextCallback();
  }
}

/**
 * Creates a custom normalized event object from touch and mouse events
 * @param  {Event} ev
 * @returns {Object} with x, y, and id properties
 */
function normalizeEvent(ev) {
  if (ev.type === "touchmove" || ev.type === "touchstart" || ev.type === "touchend") {
    var touch = ev.targetTouches[0] || ev.changedTouches[0];
    return {
      x: touch.clientX,
      y: touch.clientY,
      id: touch.identifier,
    };
  } else {
    // mouse events
    return {
      x: ev.clientX,
      y: ev.clientY,
      id: null,
    };
  }
}

/**
 * @see http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
 */
const requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

function getPassiveSupported() {
  let passiveSupported = false;

  try {
    var options = Object.defineProperty({}, "passive", {
      get: function () {
        passiveSupported = true;
      },
    });

    window.addEventListener("test", null, options);
  } catch (err) {}

  getPassiveSupported = () => passiveSupported;
  return passiveSupported;
}
