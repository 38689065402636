// import Highway from "@dogstudio/highway";

export default class App {
  constructor({ renderers = {}, transitions = {}, components = {} }) {
    this.components = components;
    this.instances = new Map();

    // this.on("NAVIGATE_IN", ({ to, trigger, location }) => {
    //   this.mountComponents(to.view);
    //   document.body.dataset.view = to.view.dataset.routerView;
    //   document.querySelectorAll("[data-app-nav] a").forEach((link) => {
    //     if (link.href === location.href) link.setAttribute("aria-current", "page");
    //     else link.removeAttribute("aria-current");
    //   });
    // });

    // this.on("NAVIGATE_OUT", ({ from, trigger, location }) => {});

    // this.on("NAVIGATE_END", ({ to, from, trigger, location }) => {
    //   this.unmountComponents(from.view);
    //   // console.log(from.view);
    // });

    this.mountComponents();
  }

  mountComponents(root = document.body) {
    const elements = root.querySelectorAll("[data-component]");

    function uniqid(prefix = "", random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000;
      const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ""}`;
    }

    elements.forEach((element) => {
      const key = element.dataset.component;
      // console.log("mount component", key);

      if (!(key in this.components)) return;

      if (this.instances.has(element)) {
        const instance = this.instances.get(element);
        instance.destroy && instance.destroy();
      }

      element.dataset.uniqid = uniqid();
      this.instances.set(element.dataset.uniqid, new this.components[key](element));
      const instance2 = this.instances.get(element);
      // console.log("unmount component", instance2,element.dataset.uniqid);
    });
  }

  unmountComponents(root = document.body) {
    const elements = root.querySelectorAll("[data-component]");

    elements.forEach((element) => {
      const instance2 = this.instances.get(element.dataset.uniqid);
      // console.log("unmount component", instance2, element.dataset.uniqid, this.instances);
      if (!this.instances.has(element)) return;

      const instance = this.instances.get(element.dataset.uniqid);
      instance.destroy && instance.destroy();
      element.instance = null;
    });
  }
}
