import Component from "../lib/Component";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Demo extends Component {
  mount({ root, trigger, dropdown }) {
    this.handler = () => {
      root.classList.toggle("open");
      const other = document.querySelectorAll("[data-component='Demo'].open");
      other.forEach((el) => {
        el !== root && el.classList.remove("open");
      });
    };

    trigger.addEventListener("click", this.handler);
  }

  unmount({ root }) {
    this.handler && trigger.removeEventListener("click", this.handler);
  }
}
