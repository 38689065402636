import Component from "../lib/Component";

export default class SequenciesHeader extends Component {
  mount({ root, toggler }) {
    // const open = () => {};

    // const outsideClick = (e) => {
    //   e.preventDefault();
    //   if (e.target.closest('[data-component="SequenciesHeader"]')) return;
    //   close();
    // };

    // const close = () => {
    //   document.removeEventListener(outsideClick);
    // };

    // console.log(root, toggler);
    toggler.addEventListener("click", (e) => {
      e.preventDefault();
      // if (root.classList.contains("open")) {
      //   root.classList.remove("open");
      // }
      root.classList.toggle("open");
      // console.log(root.classList.contains("open"));
    });
  }

  unmount({ root }) {}
}
