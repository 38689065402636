import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class DefaultTransition extends Highway.Transition {
  in({ from, to, done }) {
    // Reset Scroll

    // Remove Old View
    // from.remove();

    // // Animation

    document.body.classList.add("no-scroll");
    gsap.set(from, {
      zIndex: 0,
    });

    gsap.set(to, {
      // yPercent: 0,
      position: "fixed",
      top: 0,
      left: 0,
      // width: window.innerWidth,
      zIndex: 99999,
      // height: bounds.height,
    });

    gsap.fromTo(
      to,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: () => {
          from.remove();
          window.scrollTo(0, 0);
          gsap.set(to, { position: "relative" });

          document.body.classList.remove("no-scroll");
          window.dispatchEvent(new Event("resize"));
          ScrollTrigger.refresh(true);

          gsap.set(to, {
            // yPercent: 0,
            position: "relative",
            top: 'auto',
            left: 'auto',
            // width: "100vw",
            zIndex: 0,
            // height: bounds.height,
          });

          gsap.set(to, {
            zIndex: 0,
          });

          done();
        },
        duration: 0.5,
        // duration: 1,
      }
    );
    done();
  }

  out({ from, done }) {
    const bounds = from.getBoundingClientRect();

    // const footer = document.querySelector(".AppFooter");
    // const footerBounds = footer.getBoundingClientRect();

    // gsap.set(footer, {
    //   yPercent: 0,
    //   position: "fixed",
    //   top: bounds.top,
    //   left: footerBounds.left,
    //   width: footerBounds.width,
    //   height: footerBounds.height,
    // });
    // from.style.position = "fixed";

    done();
    // Animation
    // gsap.fromTo(
    //   from.view,
    //   { opacity: 1 },
    //   {
    //     opacity: 0,
    //     onComplete: done,
    //     duration: 0.5,
    //   }
    // );
  }
}
