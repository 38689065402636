import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Component {
  constructor(root) {
    this.root = root;
    this.elements = { root };
    this.timelines = [];

    root.component = this;

    root.querySelectorAll("[data-ref]").forEach((element) => {
      const key = element.dataset.ref;
      this.elements[key] = element;
    });

    root.querySelectorAll("[data-ref-group]").forEach((element) => {
      const key = element.dataset.refGroup;
      this.elements[key] = [...(this.elements[key] ?? []), element];
    });

    const selectors = { root, ...((this.setup && this.setup(this.elements)) ?? {}) };
    Object.entries(selectors).forEach(([key, selector]) => {
      this.elements[key] = typeof selector == "string" ? root.querySelectorAll(selector) : selector;
    });

    this.mount && this.mount(this.elements);
  }

  timeline(...params) {
    const timeline = gsap.timeline(...params);
    this.timelines.push(timeline);
    return timeline;
  }

  destroy() {
    // console.log('>>>> component destroy')
    this.unmount && this.unmount(this.elements);

    this.timelines.forEach((timeline) => timeline.kill());

    this.timelines = [];
    this.elements = [];

    this.onDestroy && this.onDestroy();
  }
}
