import Component from "../lib/Component";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default class PageNav extends Component {
  mount({ root }) {
    const sections = [...document.querySelectorAll("[data-section]")];
    const bullets = [...root.querySelectorAll("[data-track]")];

    let lastElement, lastSection;

    const setActiveSection = (element) => {
      const section = sections.find((section) => section === element || section.contains(element));
      if (!section || section === lastSection) return;
      lastSection = section;

      bullets.forEach((bullet) => {
        bullet.classList.toggle("active", bullet.dataset.track === section.dataset.section);
      });
    };

    const setActiveSchema = (element) => {
      const schema = element.closest("[data-schema]").dataset.schema;
      if (!schema) return;
      root.dataset.variant = schema === "light" ? "dark" : "light";
    };

    this.listener = () => {
      const element = document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2);
      if (lastElement === element) return;
      lastElement = element;

      setActiveSection(element);
      setActiveSchema(element);
    };

    document.addEventListener("scroll", this.listener);
    this.listener();

    bullets.forEach((bullet) => {
      bullet.addEventListener("click", (e) => {
        e.preventDefault();
        const section = sections.find((section) => section.dataset.section === bullet.dataset.track);
        gsap.to(window, { duration: 1, scrollTo: { y: section }, ease: "Power1.easeInOut" });
      });
    });
  }

  unmount({ root }) {
    this.listener && document.removeEventListener("scroll", this.listener);
  }
}
