import Component from "../lib/Component";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default class MoreInfoBtn extends Component {
  mount({ root }) {
    const form = document.querySelector("#form");

    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: form,
        // markers: true,
        onEnter() {
          root.classList.add("hidden");
        },
        onLeaveBack() {
          root.classList.remove("hidden");
        },
      },
    });

    this.listener = (e) => {
      e.preventDefault();
      gsap.to(window, { duration: 1, scrollTo: { y: form }, ease: "Power1.easeInOut" });
    };

    root.addEventListener("click", this.listener);
  }

  unmount({ root }) {
    this.listener && root.removeEventListener(this.listener);
    this.tl.kill();
  }
}
