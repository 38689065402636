// import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// ScrollTrigger.defaults({
//   toggleActions: "restart pause resume pause",
//   scroller: "main",
// });

import imagesLoaded from "imagesloaded";

import App from "./lib/App";

// Transitions
import DefaultTransition from "./transitions/Default";

// Components
import SequenciesHeader from "./components/SequenciesHeader";
import Demo from "./components/Demo";
import ParallaxElement from "./components/ParallaxElement";
import PageNav from "./components/PageNav";
import MoreInfoBtn from "./components/MoreInfoBtn";
import Anchor from "./components/Anchor";
import Video from "./components/Video";
import Youtube from "./components/Youtube";
import FeaturesFloor from "./floors/FeaturesFloor";
import MetodologiaFloor from "./floors/MetodologiaFloor";
import TallersFloor from "./floors/TallersFloor";
import TestimonialsFloor from "./floors/TestimonialsFloor";

imagesLoaded(document.body, () => {
  document.body.classList.add("loaded");

  const app = new App({
    renderers: {},

    transitions: {
      default: DefaultTransition,
    },

    components: {
      // Components
      // AppHeader,
      // Cover,
      // ImageSlider,
      // ParallaxImage,
      // VideoPopup,
      // Wireframe,
      // Flooors
      // HomeHeroFloor,
      // PhilosophyFloor,
      // // ProjectTeaserFloor,
      // ProjectSliderFloor,
      // AboutFloor,
      //

      SequenciesHeader,
      MoreInfoBtn,
      PageNav,
      ParallaxElement,
      Anchor,
      Video,
      Youtube,
      Demo,
      //
      FeaturesFloor,
      MetodologiaFloor,
      TallersFloor,
      TestimonialsFloor,
    },
  });
});
